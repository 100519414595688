export const modules = {
  WxuNextDoor: {
    clientLoader: () => import(/* webpackChunkName: "HeaderMobile" */'@wxu/modules/src/NextDoor/client.loader').then(module => module.clientLoader),
  },
  WxuHeaderMobile: {
    clientLoader: () => import(/* webpackChunkName: "HeaderMobile" */'@wxu/modules/src/HeaderMobile/client.loader').then(module => module.clientLoader),
  },
  WxuContentMedia: {
    clientLoader: () => import(/* webpackChunkName: "ContentMedia" */'@wxu/modules/src/ContentMedia/client.loader').then(module => module.clientLoader),
  },
  WxuTodayDetails: {
    clientLoader: () => import(/* webpackChunkName: "TodayDetailsCard" */'@wxu/modules/src/TodayDetailsCard/client.loader').then(module => module.clientLoader),
  },
  WxuAirQuality: {
    clientLoader: () => import(/* webpackChunkName: "AirQuality" */'@wxu/modules/src/AirQuality/client.loader').then(module => module.clientLoader),
  },
  WxuCurrentConditions: {
    clientLoader: () => import(/* webpackChunkName: "CurrentConditions" */'@wxu/modules/src/CurrentConditions/client.loader').then(module => module.clientLoader),
  },
  WxuTodayMapCard: {
    clientLoader: () => import(/* webpackChunkName: "TodayMapCard" */'@wxu/modules/src/TodayMapCard/client.loader').then(module => module.clientLoader),
  },
  WxuHealthAndActivities: {
    clientLoader: () => import(/* webpackChunkName: "HealthActivitiesCard" */'@wxu/modules/src/HealthActivitiesCard/client.loader').then(module => module.clientLoader),
  },
  WxuAd: {
    clientLoader: () => import(/* webpackChunkName: "Money" */'@wxu/modules/src/Ad/client.loader').then(module => module.clientLoader),
  },
  WxuPrimaryWeatherCard: {
    clientLoader: () => import(/* webpackChunkName: "PrimaryWeatherCard" */'@wxu/modules/src/PrimaryWeatherCard/client.loader').then(module => module.clientLoader),
  },
  Taboola: {
    clientLoader: () => import(/* webpackChunkName: "Taboola" */'@wxu/modules/src/Taboola/client.loader').then(module => module.clientLoader),
  },
  Outbrain: {
    clientLoader: () => import(/* webpackChunkName: "Outbrain" */'@wxu/modules/src/Outbrain/client.loader').then(module => module.clientLoader),
  },
  WxuDaybreakMetrics: {
    clientLoader: () => import(/* webpackChunkName: "DaybreakMetrics" */'@wxu/modules/src/DaybreakMetrics/client.loader').then(module => module.clientLoader),
  },
  WxuNavigation: {
    clientLoader: () => import(/* webpackChunkName: "Navigation" */'@wxu/modules/src/Navigation/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellCard: {
    clientLoader: () => import(/* webpackChunkName: "UpsellCard" */'@wxu/modules/src/UpsellCard/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellToast: {
    clientLoader: () => import(/* webpackChunkName: "UpsellToastMessage" */'@wxu/modules/src/UpsellToastMessage/client.loader').then(module => module.clientLoader),
  },
  WxuUpsellOverlay: {
    clientLoader: () => import(/* webpackChunkName: "UpsellOverlay" */'@wxu/modules/src/UpsellOverlay/client.loader').then(module => module.clientLoader),
  },
  WxuNewsroom: {
    clientLoader: () => import(/* webpackChunkName: "Newsroom" */'@wxu/modules/src/Newsroom/client.loader').then(module => module.clientLoader),
  },
  WxuLocationConfirmation: {
    clientLoader: () => import(/* webpackChunkName: "LocationConfirmation" */'@wxu/modules/src/LocationConfirmation/client.loader').then(module => module.clientLoader),
  },
  WxuHurricaneTrackerCard: {
    clientLoader: () => import(/* webpackChunkName: "HurricaneTrackerCard" */'@wxu/modules/src/HurricaneTrackerCard/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyDataNotice: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyDataNotice" */'@wxu/modules/src/PrivacyDataNotice/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyFeedCard: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyFeedCard" */'@wxu/modules/src/PrivacyFeedCard/client.loader').then(module => module.clientLoader),
  },
  WxuMedalliaSurvey: {
    clientLoader: () => import(/* webpackChunkName: "MedalliaSurvey" */'@wxu/modules/src/MedalliaSurvey/client.loader').then(module => module.clientLoader),
  },
  WxuWatsonAllergyCard: {
    clientLoader: () => import(/* webpackChunkName: "WatsonAllergyCard" */'@wxu/modules/src/WatsonAllergyCard/client.loader').then(module => module.clientLoader),
  },
  WxuFlexMap: {
    clientLoader: () => import(/* webpackChunkName: "FlexMapCard" */'@wxu/modules/src/FlexMapCard/client.loader').then(module => module.clientLoader),
  },
  WxuPromoDriver: {
    clientLoader: () => import(/* webpackChunkName: "PromoDriver" */'@wxu/modules/src/PromoDriver/client.loader').then(module => module.clientLoader),
  },
  WxuTodayWeatherCard: {
    clientLoader: () => import(/* webpackChunkName: "TodayWeatherCard" */'@wxu/modules/src/TodayWeatherCard/client.loader').then(module => module.clientLoader),
  },
  WxuDailyWeatherCard: {
    clientLoader: () => import(/* webpackChunkName: "DailyWeatherCard" */'@wxu/modules/src/DailyWeatherCard/client.loader').then(module => module.clientLoader),
  },
  WxuHourlyWeatherCard: {
    clientLoader: () => import(/* webpackChunkName: "HourlyWeatherCard" */'@wxu/modules/src/HourlyWeatherCard/client.loader').then(module => module.clientLoader),
  },
  WxuFooter: {
    clientLoader: () => import(/* webpackChunkName: "Footer" */'@wxu/modules/src/Footer/client.loader').then(module => module.clientLoader),
  },
  WxuLocalsuiteNav: {
    clientLoader: () => import(/* webpackChunkName: "LocalsuiteNav" */'@wxu/modules/src/LocalsuiteNav/client.loader').then(module => module.clientLoader),
  },
  WxuHeaderLargeScreen: {
    clientLoader: () => import(/* webpackChunkName: "HeaderLargeScreen" */'@wxu/modules/src/HeaderLargeScreen/client.loader').then(module => module.clientLoader),
  },
  WxuSavedLocations: {
    clientLoader: () => import(/* webpackChunkName: "SavedLocations" */'@wxu/modules/src/SavedLocations/client.loader').then(module => module.clientLoader),
  },
  WxuSmartMessage: {
    clientLoader: () => import(/* webpackChunkName: "SmartMessage" */'@wxu/modules/src/SmartMessage/client.loader').then(module => module.clientLoader),
  },
  WxuSurveyCard: {
    clientLoader: () => import(/* webpackChunkName: "SurveyCard" */'@wxu/modules/src/SurveyCard/client.loader').then(module => module.clientLoader),
  },
  WxuPrecipIntensityCard: {
    clientLoader: () => import(/* webpackChunkName: "PrecipIntensityCard" */'@wxu/modules/src/PrecipIntensityCard/client.loader').then(module => module.clientLoader),
  },
  WxuInsightBentoCard: {
    clientLoader: () => import(/* webpackChunkName: "InsightBentoCard" */'@wxu/modules/src/InsightBentoCard/client.loader').then(module => module.clientLoader),
  },
  WxuPollCard: {
    clientLoader: () => import(/* webpackChunkName: "InsightBentoCard" */'@wxu/modules/src/Poll/client.loader').then(module => module.clientLoader),
  },
  WxuMobileWebBanner: {
    clientLoader: () => import(/* webpackChunkName: "MobileWebBanner" */'@wxu/modules/src/MobileWebBanner/client.loader').then(module => module.clientLoader),
  },
  WxuWatsonMomentsFluInsights: {
    clientLoader: () => import(/* webpackChunkName: "WatsonMomentsFluInsights" */'@wxu/modules/src/WatsonMomentsFluInsights/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyConsentModal: {
    clientLoader: () => import(/* webpackChunkName: "WxuPrivacyConsentModal" */'@wxu/modules/src/PrivacyConsentModal/client.loader').then(module => module.clientLoader),
  },
  SamsungWeatherConditionsGraphs: {
    clientLoader: () => import(/* webpackChunkName: "SamsungWeatherConditionsGraphs" */'@wxu/modules/src/SamsungWeatherCondition/client.loader').then(module => module.clientLoader),
  },
  WxuTextSummaryCard: {
    clientLoader: () => import(/* webpackChunkName: "WxuTextSummaryCard" */'@wxu/modules/src/TextSummaryCard/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubDriver: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubDriver" */'@wxu/modules/src/ActivitiesHubDriver/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubHeader: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubHeader" */'@wxu/modules/src/ActivitiesHubHeader/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubForecastDetails: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubForecastDetails" */'@wxu/modules/src/ActivitiesHubForecastDetails/client.loader').then(module => module.clientLoader),
  },
  AnonymousNewsletter: {
    clientLoader: () => import(/* webpackChunkName: "AnonymousNewsletter" */'@wxu/modules/src/AnonymousNewsletter/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesForecastCarousel: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesForecastCarousel" */'@wxu/modules/src/ActivitiesForecastCarousel/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubForecastBarGraph: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubForecastBarGraph" */'@wxu/modules/src/ActivitiesHubForecastBarGraph/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubDailyForecast: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubDailyForecast" */'@wxu/modules/src/ActivitiesHubDailyForecast/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubCarousel: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubCarousel" */'@wxu/modules/src/ActivitiesHubCarousel/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubTipOfTheDay: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubTipOfTheDay" */'@wxu/modules/src/ActivitiesHubTipOfTheDay/client.loader').then(module => module.clientLoader),
  },
  WxuOpenContentIframe: {
    clientLoader: () => import(/* webpackChunkName: "OpenContentIframe" */'@wxu/modules/src/OpenContentIframe/client.loader').then(module => module.clientLoader),
  },
  WxuBreakingNewsTicker: {
    clientLoader: () => import(/* webpackChunkName: "BreakingNewsTicker" */'@wxu/modules/src/BreakingNewsTicker/client.loader').then(module => module.clientLoader),
  },
  WxuPuzzmoEmbed: {
    clientLoader: () => import(/* webpackChunkName: "PuzzmoEmbed" */'@wxu/modules/src/PuzzmoEmbed/client.loader').then(module => module.clientLoader),
  },
  WxuActivitiesHubSupremeGolf: {
    clientLoader: () => import(/* webpackChunkName: "ActivitiesHubSupremeGolf" */'@wxu/modules/src/ActivitiesHubSupremeGolf/client.loader').then(module => module.clientLoader),
  },
  // APPEND_MODULE
};
